import React from "react"
import { Link } from "gatsby"

import { Flex } from "@chakra-ui/react";


import Logo from "@components/atoms/Logo/Logo";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 20,
}

const linkStyles = {
  backgroundColor: "#000000",
  color: "#FFFFFF",
  padding: "1rem",
  width: "100%",
  borderRadius: "0",
  textTransform: "uppercase",
  display: "block",
  marginTop: 20
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry we couldn’t find what you were looking for.
        <br />
        <Link to="/" style={linkStyles}>Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
